.hero-cont {
  height: 671px;
  flex-shrink: 0;
  width: 88%;
  display: flex;
  flex-direction: column;
  margin: 20px 0 100px 0;
  position: relative;
}
.hero-pn {
  width: 100%;
  height: 70.6px;
}
.hero-logo {
  width: 80px;
  height: 40px;
}
.hero-logo img {
  width: 100%;
}
.hero-nav {
  display: flex;
  padding: 12.5px 28px;
  justify-content: space-between;
  align-items: center;

  border-radius: 10px;
  background: rgba(20, 20, 22, 0.5);
  backdrop-filter: blur(100px);
  width: 88%;
  box-sizing: border-box;

  color: rgba(255, 255, 255, 0.75);
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: fixed;
  z-index: 1111111;
  top: 20px;
}

.hero-nav-right {
  display: flex;
  gap: 40px;
  align-items: center;
}

.nav-btn-cta {
  display: flex;
  padding: 10px 34px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #000;

  border-radius: 24px;
}

.hero-stars {
  position: absolute;
  width: 100%;
  opacity: 0;
  top: -90px;
  z-index: 1;

  transition: all 0s 0.3s ease-in-out;
}
.hero-cta-border-inner {
  opacity: 1;
  overflow: hidden;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: linear-gradient(
      269.97deg,
      #09668e 0.03%,
      #65bc8f 46.85%,
      #ff7c00 99.95%
    ),
    linear-gradient(0deg, #000000, #000000);
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
  transition: top 0.3s ease-in-out, right 0.3s ease-in-out;
}
.hero-cta-border-inner::after {
  content: "";
  width: 10%;
  background-color: #ffffffe4;
  height: 200%;
  position: absolute;
  transform: rotate(-45deg);
  left: -35%;
  transition: all 01s ease;
}

.hero-cta-border:hover {
  transform: rotate(-15deg) scale(0.85);
}
.hero-cta-border:hover .hero-stars {
  opacity: 1;
}
.hero-cta-border:hover .hero-cta-border-inner {
  opacity: 1;
  top: -15px;
  right: -15px;
}
.hero-cta-border:hover .hero-cta-border-inner::after {
  left: 135%;
}
.hero-main-cont {
  width: 100%;
  height: 523px;
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  gap: 10px;
  position: relative;
}
.hero-main-left {
  width: calc(100% - 399px);
  height: 523px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 84px;
  position: relative;
}
.hero-main-head {
  color: rgba(255, 255, 255, 0.6);
  font-family: Gilroy;
  font-size: 64px;
  font-style: normal;
  font-weight: 300;
  line-height: 84px;
  text-transform: capitalize;
  z-index: 1;
}

.hero-main-head span {
  font-family: Archivo Black;
  font-size: 72px;
  font-style: normal;
  font-weight: 400;
  line-height: 84px; /* 116.667% */
  text-transform: capitalize;

  background: var(
    --Brand-Gradient-2,
    linear-gradient(270deg, #09668e 0.03%, #65bc8f 60.07%, #38b4e9 99.98%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-cta-border {
  cursor: pointer;
  z-index: 11;
  position: relative;
  padding: 2px;
  border-radius: 30px;
  background: linear-gradient(
      269.97deg,
      #09668e 0.03%,
      #65bc8f 46.85%,
      #ff7c00 99.95%
    ),
    linear-gradient(0deg, #000000, #000000);
  width: fit-content;
  transition: all 0.5s ease-in-out;
}
.hero-cta {
  display: flex;
  width: 256px;
  padding: 15px 3px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 30px;

  background: #000;
  color: #fff;
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-sizing: border-box;
}

.ecl-img {
  position: relative;
}
.hero-socials {
  display: flex;
  gap: 20px;
  width: 100%;
  align-items: center;
}
a {
  text-decoration: none;
  color: inherit;
}
.hero-socials > a {
  text-decoration: none;
  width: calc(33.33% - 60px);
}
.hovstop1 {
  display: none !important;
}
.hovstop:hover {
  transform: none !important;
}
.hero-si {
  cursor: pointer;
  display: flex;
  gap: 15px;
  width: calc(33.33% - 60px);
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: rgba(20, 20, 22, 0.25);
  padding: 15px 30px;
  box-sizing: border-box;
}
.hero-si span {
  color: rgba(255, 255, 255, 0.75);
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.hero-si svg {
  width: 32px;
}
.eclipse5 {
  border-radius: 704px;
  background: linear-gradient(
    270deg,
    rgba(9, 102, 142, 0.2) 0.03%,
    rgba(101, 188, 143, 0.2) 46.85%,
    rgba(255, 124, 0, 0.2) 99.95%
  );
  filter: blur(79.38903045654297px);
  width: 120%;
  height: 120%;
  transform: rotate(-123.208deg);
  flex-shrink: 0;
  position: absolute;
  top: -15%;
}

@media screen and (max-width: 1000px) {
  .hero-cont {
    height: auto;
    flex-shrink: 0;
    width: 95%;
    display: flex;
    flex-direction: column;
    margin: 20px 0 0px 0;
    position: relative;
    justify-content: center;
    align-items: center;
  }
  .hovstop1 {
    display: flex !important;
  }
  .hero-nav {
    display: flex;
    padding: 12.5px 28px;
    justify-content: space-between;
    align-items: center;

    border-radius: 10px;
    background: rgba(20, 20, 22, 0.5);
    backdrop-filter: blur(100px);
    width: 95%;
    box-sizing: border-box;

    color: rgba(255, 255, 255, 0.75);
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .hero-nav-right {
    display: flex;
    gap: 40px;
    align-items: center;
    display: none;
  }

  .nav-btn-cta {
    display: flex;
    padding: 10px 34px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    border: 2px solid var(--Brand-Gradient, #09668e);
  }

  .hero-main-cont {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    flex-direction: column;
    margin-top: 56px;
  }

  .hero-main-left {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: relative;
  }
  .hero-main-head {
    color: rgba(255, 255, 255, 0.6);
    font-family: Gilroy;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    z-index: 1;
    width: 100%;
    text-align: center;
  }

  .hero-main-head span {
    font-size: 40px;
    line-height: normal;
  }

  .hero-cta {
    align-self: center;
  }

  .hero-abs-img {
    width: 100%;
  }
  .ecl-img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    align-self: center;
  }
  .hero-socials {
    display: flex;
    gap: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .hero-si {
    padding: 10px;
  }

  .eclipse5 {
    border-radius: 704px;
    background: linear-gradient(
      270deg,
      rgba(9, 102, 142, 0.2) 0.03%,
      rgba(101, 188, 143, 0.2) 46.85%,
      rgba(255, 124, 0, 0.2) 99.95%
    );
    filter: blur(79.38903045654297px);
    width: 120%;
    height: 120%;
    transform: rotate(-123.208deg);
    flex-shrink: 0;
    position: absolute;
    top: -15%;
  }
}

@media screen and (max-width: 600px) {
  /* .hero-si-float {
    position: fixed;
    bottom: 20px;
    right: 0px;
    cursor: pointer;
    z-index: 111;
  } */
  .hero-cont {
    height: auto;
    flex-shrink: 0;
    width: 95%;
    display: flex;
    flex-direction: column;
    margin: 20px 0 0px 0;
    position: relative;
    justify-content: center;
    align-items: center;
  }
  .hero-nav {
    display: flex;
    padding: 12.5px 28px;
    justify-content: space-between;
    align-items: center;

    border-radius: 10px;
    background: rgba(20, 20, 22, 0.5);
    backdrop-filter: blur(100px);
    width: 95%;
    box-sizing: border-box;

    color: rgba(255, 255, 255, 0.75);
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .hero-nav-right {
    display: flex;
    gap: 40px;
    align-items: center;
    display: none;
  }

  .nav-btn-cta {
    display: flex;
    padding: 10px 34px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    border: 2px solid var(--Brand-Gradient, #09668e);
  }

  .hero-main-cont {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    flex-direction: column;
    margin-top: 56px;
  }

  .hero-main-left {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    position: relative;
  }
  .hero-main-head {
    color: rgba(255, 255, 255, 0.6);
    font-family: Gilroy;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    z-index: 1;
    width: 100%;
    text-align: center;
  }

  .hero-main-head span {
    font-size: 40px;
    line-height: normal;
  }

  .hero-cta {
    align-self: center;
  }

  .hero-abs-img {
    width: 100%;
  }
  .ecl-img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    align-self: center;
  }
  .hero-socials {
    display: flex;
    gap: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .hero-si {
    padding: 10px;
  }
  .hero-si span {
    display: none;
  }

  .eclipse5 {
    border-radius: 704px;
    background: linear-gradient(
      270deg,
      rgba(9, 102, 142, 0.2) 0.03%,
      rgba(101, 188, 143, 0.2) 46.85%,
      rgba(255, 124, 0, 0.2) 99.95%
    );
    filter: blur(79.38903045654297px);
    width: 120%;
    height: 120%;
    transform: rotate(-123.208deg);
    flex-shrink: 0;
    position: absolute;
    top: -15%;
  }
}
