.foot-cont {
  background: rgba(255, 255, 255, 0.02);
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 60px 0;
  position: relative;
}
.foot-inner-cont {
  width: 88%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.foot-icon-cont > a {
  width: fit-content;
}

.foot-head {
  color: rgba(255, 255, 255, 0.6);
  font-family: Noto Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: flex-start;
}
.foot-section-cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.foot-section {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.foot-inp-cont {
  width: fit-content;
  position: relative;
}
.foot-inp-cont > input {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  background-color: transparent;
  display: inline-flex;
  padding: 12px;
  justify-content: flex-end;
  align-items: center;
  gap: 67px;
  width: 400px;
  height: 60px;
  box-sizing: border-box;

  color: rgba(255, 255, 255, 0.25);
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.foot-inp-cont > input::placeholder {
  color: rgba(255, 255, 255, 0.25);
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.foot-inp-abs {
  position: absolute;
  right: 0;
  top: 0;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #ff7c00;
  box-sizing: border-box;
  padding: 12px;
}
.foot-inp-abs svg {
  width: 30px;
  height: 30px;
}

.foot-reg-cont {
  margin-top: 35px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 11px;
}

.foot-sec-head {
  z-index: 11;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.75);
  font-family: Noto Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.foot-reg-text {
  color: rgba(255, 255, 255, 0.6);
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.foot-ser-cont {
  display: flex;
  align-items: flex-start;
  gap: 60px;
}

.foot-ser-cat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}
.foot-ser-text {
  color: rgba(255, 255, 255, 0.6);
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.foot-icon-cont {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.foot-line {
  background: rgba(255, 255, 255, 0.05);
  height: 2px;
  width: 100%;
  margin: 10px 0;
}

.foot-copy {
  color: rgba(255, 255, 255, 0.45);
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ecl6 {
  width: 839.719px;
  height: 839.719px;
  transform: rotate(1.421deg);
  flex-shrink: 0;
  border-radius: 839.719px;
  background: radial-gradient(
    3738.98% 148.42% at 50% 58.71%,
    rgba(9, 102, 142, 0.3) 0%,
    rgba(56, 180, 233, 0.3) 100%,
    rgba(101, 188, 143, 0.3) 100%
  );
  filter: blur(320.9936828613281px);
  position: absolute;
  left: 0;
}

@media screen and (max-width: 1000px) {
  .foot-cont {
    background: rgba(255, 255, 255, 0.02);
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 60px 0;
    position: relative;
  }
  .foot-inner-cont {
    width: 88%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .foot-head {
    color: rgba(255, 255, 255, 0.6);
    font-family: Noto Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: flex-start;
  }
  .foot-section-cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 50px;
  }
  .foot-section {
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 400px;
  }

  .foot-inp-cont {
    width: 100%;
    position: relative;
  }
  .foot-inp-cont > input {
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    background-color: transparent;
    display: inline-flex;
    padding: 12px;
    justify-content: flex-end;
    align-items: center;
    gap: 67px;
    width: 100%;
    height: 60px;
    box-sizing: border-box;

    color: rgba(255, 255, 255, 0.25);
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .foot-inp-cont > input::placeholder {
    color: rgba(255, 255, 255, 0.25);
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .foot-inp-abs {
    position: absolute;
    right: 0;
    top: 0;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #ff7c00;
    box-sizing: border-box;
    padding: 12px;
  }
  .foot-inp-abs svg {
    width: 30px;
    height: 30px;
  }

  .foot-reg-cont {
    margin-top: 35px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 11px;
  }

  .foot-sec-head {
    color: rgba(255, 255, 255, 0.75);
    font-family: Noto Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .foot-reg-text {
    color: rgba(255, 255, 255, 0.6);
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .foot-ser-cont {
    display: flex;
    align-items: flex-start;
  }

  .foot-ser-cat {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  .foot-ser-text {
    color: rgba(255, 255, 255, 0.6);
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .foot-icon-cont {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }

  .foot-line {
    background: rgba(255, 255, 255, 0.05);
    height: 2px;
    width: 100%;
    margin: 10px 0;
  }

  .foot-copy {
    color: rgba(255, 255, 255, 0.45);
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ecl6 {
    width: 839.719px;
    height: 839.719px;
    transform: rotate(1.421deg);
    flex-shrink: 0;
    border-radius: 839.719px;
    background: radial-gradient(
      3738.98% 148.42% at 50% 58.71%,
      rgba(9, 102, 142, 0.3) 0%,
      rgba(56, 180, 233, 0.3) 100%,
      rgba(101, 188, 143, 0.3) 100%
    );
    filter: blur(320.9936828613281px);
    position: absolute;
    left: 0;
  }
}

@media screen and (max-width: 600px) {
  .foot-cont {
    background: rgba(255, 255, 255, 0.02);
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 60px 0;
    position: relative;
  }
  .foot-inner-cont {
    width: 88%;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .foot-head {
    color: rgba(255, 255, 255, 0.6);
    font-family: Noto Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: flex-start;
  }
  .foot-section-cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 50px;
  }
  .foot-section {
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 400px;
  }

  .foot-inp-cont {
    width: 100%;
    position: relative;
  }
  .foot-inp-cont > input {
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    background-color: transparent;
    display: inline-flex;
    padding: 12px;
    justify-content: flex-end;
    align-items: center;
    gap: 67px;
    width: 100%;
    height: 60px;
    box-sizing: border-box;

    color: rgba(255, 255, 255, 0.25);
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .foot-inp-cont > input::placeholder {
    color: rgba(255, 255, 255, 0.25);
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .foot-inp-abs {
    position: absolute;
    right: 0;
    top: 0;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #ff7c00;
    box-sizing: border-box;
    padding: 12px;
  }
  .foot-inp-abs svg {
    width: 30px;
    height: 30px;
  }

  .foot-reg-cont {
    margin-top: 35px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 11px;
  }

  .foot-sec-head {
    color: rgba(255, 255, 255, 0.75);
    font-family: Noto Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .foot-reg-text {
    color: rgba(255, 255, 255, 0.6);
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .foot-ser-cont {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    flex-direction: column;
  }

  .foot-ser-cat {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  .foot-ser-text {
    color: rgba(255, 255, 255, 0.6);
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .foot-icon-cont {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }

  .foot-line {
    background: rgba(255, 255, 255, 0.05);
    height: 2px;
    width: 100%;
    margin: 10px 0;
  }

  .foot-copy {
    color: rgba(255, 255, 255, 0.45);
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ecl6 {
    width: 839.719px;
    height: 839.719px;
    transform: rotate(1.421deg);
    flex-shrink: 0;
    border-radius: 839.719px;
    background: radial-gradient(
      3738.98% 148.42% at 50% 58.71%,
      rgba(9, 102, 142, 0.3) 0%,
      rgba(56, 180, 233, 0.3) 100%,
      rgba(101, 188, 143, 0.3) 100%
    );
    filter: blur(320.9936828613281px);
    position: absolute;
    left: 0;
  }
}
