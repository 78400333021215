.rw-cont {
  width: 88%;
  display: flex;
  flex-direction: column;
  gap: 45px;
  padding: 50px 0;
  align-items: center;
  position: relative;
}
.rw-inner-cont {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.rw-left-cont {
  width: calc(40% - 10px);
  display: flex;
  border-radius: 15px;
  border: 1px solid rgba(9, 102, 142, 0.4);
  background: linear-gradient(
    150deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  backdrop-filter: blur(50px);
  height: 461px;
  flex-direction: column;
  justify-content: flex-start;
}

.rw-left-head {
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  font-family: Gilroy;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}
.rw-left-opt {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 0;
}
.rw-opt-act {
  background: rgba(255, 255, 255, 0.08);
}
.ddf {
  width: 100%;
  height: 100%;
}
.rw-right-i {
  width: calc(60% - 10px);
  background: linear-gradient(
    111deg,
    rgba(0, 0, 0, 0.7) 1.29%,
    rgba(0, 0, 0, 0.4) 100%
  );
  height: fit-content;
  /* border-radius: 20px; */
  z-index: 111;
}
.rw-right-cont {
  width: calc(60% - 10px);
  height: 461px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid rgba(9, 102, 142, 0.5);
  background: linear-gradient(
    111deg,
    rgba(0, 0, 0, 0.7) 1.29%,
    rgba(0, 0, 0, 0.4) 100%
  );
  backdrop-filter: blur(20px);
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.rw-right-top {
  z-index: 11;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin: 30px 0 0 30px;
  width: 70%;
}
.rw-right-top > img {
  max-width: 100px;
  max-height: 60px;
}

.rw-right-head {
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  font-family: Gilroy;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.rw-right-text {
  color: rgba(255, 255, 255, 0.6);
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.rw-right-btm {
  width: fit-content;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.03);
  display: inline-flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0 0 40px 30px;
}
.rw-btm-text {
  background: var(
    --Brand-Gradient-2,
    linear-gradient(270deg, #09668e 0.03%, #65bc8f 60.07%, #38b4e9 99.98%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.doc-abs {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30%;
  opacity: 0.7;
  aspect-ratio: 1/1;
  max-height: 80%;
  border-radius: 20px;
}

.rw-arrows {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 111;
}
.rw-arr {
  border-radius: 0px 10px 10px 0px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  width: 100px;
  height: 52px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.rw-less {
  border-radius: 10px 0px 0px 10px;
  background: rgba(255, 255, 255, 0.1);
}

.rw-cta-border {
  padding: 2px;
  background: linear-gradient(
      269.97deg,
      #09668e 0.03%,
      #65bc8f 46.85%,
      #ff7c00 99.95%
    ),
    linear-gradient(0deg, #000000, #000000);
  border-radius: 30px;
  height: fit-content;
  width: fit-content;
}
.rw-cta {
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rw-cta-btn {
  color: #fff;
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 30px;
  /* border: 2px solid var(--Brand-Gradient, #09668e); */
  background: #000;

  padding: 20px 70px;
  display: flex;
  align-items: center;
}

.ecl2 {
  position: absolute;
  width: 1105.206px;
  height: 1105.206px;
  transform: rotate(1.421deg);
  flex-shrink: 0;
  border-radius: 1105.206px;
  background: linear-gradient(
    269deg,
    rgba(217, 156, 99, 0.2) -109.54%,
    rgba(101, 188, 143, 0.2) -11.66%,
    rgba(255, 124, 0, 0.2) 99.33%
  );
  filter: blur(293.69952392578125px);
  left: -45%;
  top: -35%;
}

.ecl3 {
  border-radius: 655.708px;
  background: linear-gradient(
    269deg,
    rgba(9, 102, 142, 0.2) -109.54%,
    rgba(101, 188, 143, 0.2) 47.07%,
    rgba(255, 124, 0, 0.2) 99.33%
  );
  filter: blur(174.24896240234375px);
  width: 655.708px;
  height: 655.708px;
  transform: rotate(1.421deg);
  flex-shrink: 0;
  position: absolute;
  right: -15%;
  bottom: -5%;
}

@media screen and (max-width: 1000px) {
  .rw-cont {
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 50px 0;
    align-items: center;
    position: relative;
  }
  .rw-inner-cont {
    display: flex;
    flex-direction: column;
  }

  .rw-left-cont {
    width: 100%;
    display: flex;
    overflow: scroll;
    height: auto;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }

  .rw-left-head {
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
    font-family: Gilroy;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    display: none;
  }
  .rw-left-opt {
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
    width: fit-content !important;
    flex-shrink: 0;
    border-radius: 15px;
  }
  .rw-opt-act {
    background: rgba(255, 255, 255, 0.08);
  }

  .rw-right-cont {
    width: 100%;
    height: 461px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid rgba(9, 102, 142, 0.5);
    background: linear-gradient(
      111deg,
      rgba(0, 0, 0, 0.7) 1.29%,
      rgba(0, 0, 0, 0.4) 100%
    );
    backdrop-filter: blur(20px);
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }

  .rw-right-top {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin: 30px 0 0 30px;
    width: 70%;
  }

  .rw-right-head {
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
    font-family: Gilroy;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .rw-right-text {
    color: rgba(255, 255, 255, 0.6);
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .rw-right-btm {
    width: fit-content;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.03);
    display: inline-flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0 0 40px 30px;
    z-index: 11;
  }
  .rw-btm-text {
    background: var(
      --Brand-Gradient-2,
      linear-gradient(270deg, #09668e 0.03%, #65bc8f 60.07%, #38b4e9 99.98%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: Noto Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .doc-abs {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30%;
  }

  .rw-arrows {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .rw-arr {
    border-radius: 0px 10px 10px 0px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    width: 100px;
    height: 52px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  .rw-less {
    border-radius: 10px 0px 0px 10px;
    background: rgba(255, 255, 255, 0.1);
  }

  .rw-cta {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rw-cta-btn {
    color: #fff;
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    border-radius: 30px;
    border: 2px solid var(--Brand-Gradient, #09668e);
    background: #000;

    padding: 20px 70px;
    display: flex;
    align-items: center;
    margin-top: 70px;
  }

  .ecl2 {
    position: absolute;
    width: 1105.206px;
    height: 1105.206px;
    transform: rotate(1.421deg);
    flex-shrink: 0;
    border-radius: 1105.206px;
    background: linear-gradient(
      269deg,
      rgba(217, 156, 99, 0.2) -109.54%,
      rgba(101, 188, 143, 0.2) -11.66%,
      rgba(255, 124, 0, 0.2) 99.33%
    );
    filter: blur(293.69952392578125px);
    left: -45%;
    top: -35%;
  }

  .ecl3 {
    border-radius: 655.708px;
    background: linear-gradient(
      269deg,
      rgba(9, 102, 142, 0.2) -109.54%,
      rgba(101, 188, 143, 0.2) 47.07%,
      rgba(255, 124, 0, 0.2) 99.33%
    );
    filter: blur(174.24896240234375px);
    width: 655.708px;
    height: 655.708px;
    transform: rotate(1.421deg);
    flex-shrink: 0;
    position: absolute;
    right: -15%;
    bottom: -5%;
  }
  .rw-right-i {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .rw-cont {
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 50px 0;
    align-items: center;
    position: relative;
  }
  .rw-inner-cont {
    display: flex;
    flex-direction: column;
  }

  .rw-left-cont {
    width: 100%;
    display: flex;
    overflow: scroll;
    height: auto;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
  }

  .rw-left-head {
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
    font-family: Gilroy;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    display: none;
  }
  .rw-left-opt {
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
    width: fit-content !important;
    flex-shrink: 0;
    border-radius: 15px;
  }
  .rw-opt-act {
    background: rgba(255, 255, 255, 0.08);
  }

  .rw-right-i {
    width: 100%;
  }
  .rw-right-cont {
    width: 100%;
    height: 461px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid rgba(9, 102, 142, 0.5);
    background: linear-gradient(
      111deg,
      rgba(0, 0, 0, 0.7) 1.29%,
      rgba(0, 0, 0, 0.4) 100%
    );
    backdrop-filter: blur(20px);
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }

  .rw-right-top {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin: 30px 0 0 30px;
    width: 90%;
  }

  .rw-right-head {
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
    font-family: Gilroy;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .rw-right-text {
    color: rgba(255, 255, 255, 0.6);
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .rw-right-btm {
    width: fit-content;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.03);
    display: inline-flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0 0 40px 30px;
  }
  .rw-btm-text {
    background: var(
      --Brand-Gradient-2,
      linear-gradient(270deg, #09668e 0.03%, #65bc8f 60.07%, #38b4e9 99.98%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: Noto Sans;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .doc-abs {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 39%;
  }

  .rw-arrows {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .rw-arr {
    border-radius: 0px 10px 10px 0px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    width: 100px;
    height: 52px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  .rw-less {
    border-radius: 10px 0px 0px 10px;
    background: rgba(255, 255, 255, 0.1);
  }

  .rw-cta {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rw-cta-btn {
    color: #fff;
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    border-radius: 30px;
    border: 2px solid var(--Brand-Gradient, #09668e);
    background: #000;

    padding: 20px 70px;
    display: flex;
    align-items: center;
    margin-top: 70px;
  }

  .ecl2 {
    position: absolute;
    width: 1105.206px;
    height: 1105.206px;
    transform: rotate(1.421deg);
    flex-shrink: 0;
    border-radius: 1105.206px;
    background: linear-gradient(
      269deg,
      rgba(217, 156, 99, 0.2) -109.54%,
      rgba(101, 188, 143, 0.2) -11.66%,
      rgba(255, 124, 0, 0.2) 99.33%
    );
    filter: blur(293.69952392578125px);
    left: -45%;
    top: -35%;
  }

  .ecl3 {
    border-radius: 655.708px;
    background: linear-gradient(
      269deg,
      rgba(9, 102, 142, 0.2) -109.54%,
      rgba(101, 188, 143, 0.2) 47.07%,
      rgba(255, 124, 0, 0.2) 99.33%
    );
    filter: blur(174.24896240234375px);
    width: 655.708px;
    height: 655.708px;
    transform: rotate(1.421deg);
    flex-shrink: 0;
    position: absolute;
    right: -15%;
    bottom: -5%;
  }
}
