.ser-cont {
  width: 88%;
  display: flex;
  flex-direction: column;
  gap: 45px;
  padding: 50px 0;
  align-items: center;
}

.ser-head {
  color: rgba(255, 255, 255, 0.75);
  font-family: Noto Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ser-card-out-cont {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.ser-card-cont {
  display: flex;
  gap: 20px;
  width: 100%;
}

.ser-card {
  display: flex;
  padding: 28px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.08);
  width: calc(25% - 15px);
}
.ser-card img {
  height: 169px;
  width: 100%;
}

.ser-card-head {
  color: rgba(255, 255, 255, 0.75);
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
.ser-card-det {
  color: rgba(239, 239, 239, 0.6);
  text-align: center;
  font-family: Gilroy;
  font-size: clamp(12px, 1vw, 16px);
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 125% */
  width: 90%;
}

.ser-enq-now {
  z-index:11;
  border-radius: 40px;
  background: #09668e;
  display: flex;
  width: 500px;
  height: 80px;
  align-items: center;
  justify-content: center;
  gap: 164px;
  color: #fff;
  font-family: Noto Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
  overflow: hidden;
}
.ser-enq-now::after {
  content: "";
  width: 7%;
  height: 200%;
  transform: rotate(-45deg);
  position: absolute;
  background-color: #ffffffd7;
  animation: glare 2s ease-in infinite;
}

@keyframes glare {
  0% {
    left: -135%;
  }
  100% {
    left: 135%;
  }
}

@media screen and (max-width: 1000px) {
  .ser-cont {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 45px;
    padding: 50px 0;
    align-items: center;
  }

  .ser-head {
    color: rgba(255, 255, 255, 0.75);
    font-family: Noto Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .ser-card-out-cont {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    overflow: scroll;
  }

  .ser-card-cont {
    display: flex;
    gap: 20px;
    width: fit-content;
  }

  .ser-card {
    display: flex;
    padding: 18px 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.08);
    width: 65vw;
    min-width: 300px;
    max-width: 340px;
  }
  .ser-card img {
    height: 169px;
    width: 100%;
  }

  .ser-card-head {
    color: rgba(255, 255, 255, 0.75);
    font-family: Gilroy;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }
  .ser-card-det {
    color: rgba(239, 239, 239, 0.6);
    text-align: center;
    font-family: Gilroy;
    font-size: clamp(12px, 1vw, 16px);
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 125% */
    width: 90%;
  }

  .ser-enq-now {
    border-radius: 40px;
    background: #09668e;
    display: flex;
    width: 80vw;
    height: 80px;
    align-items: center;
    justify-content: center;
    gap: 164px;
    color: #fff;
    font-family: Noto Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
