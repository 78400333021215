.contact-cont {
  width: 88%;
  display: flex;
  flex-direction: column;

  border-radius: 15px;
  background: rgba(255, 255, 255, 0.05);
  margin: 50px 0;
  box-sizing: border-box;
  padding: 60px 0;
  gap: 20px;
}

.ct-inner-cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ct-thank {
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  font-family: Gilroy;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ct-thank-text {
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  font-family: Gilroy;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ct-left {
  height: 100%;
  width: 45%;
  display: flex;
  align-items: flex-end;
}
.ct-left img {
  width: 100%;
}
.ct-right {
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
}

.ct-right > input {
  display: flex;
  width: 80%;
  height: 80px;
  padding: 18px;
  align-items: center;
  flex-shrink: 0;

  border-radius: 10px;
  border: 1.5px solid rgba(255, 255, 255, 0.6);
  background-color: transparent;

  color: rgba(255, 255, 255, 0.6);
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  box-sizing: border-box;
}

.ct-right > input::placeholder {
  color: rgba(255, 255, 255, 0.6);
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ct-cta {
  cursor: pointer;
  border-radius: 10px;
  background: #ff7c00;
  display: flex;
  width: 80%;
  padding: 24px 0px 23px 0px;
  justify-content: center;
  align-items: center;

  color: #fff;
  font-family: Noto Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 15px;
}

@media screen and (max-width: 1000px) {
  .contact-cont {
    width: 95%;
    display: flex;
    flex-direction: column;

    border-radius: 15px;
    background: rgba(255, 255, 255, 0.05);
    margin: 50px 0;
    box-sizing: border-box;
    padding: 60px 0;
    gap: 20px;
  }

  .ct-inner-cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;
    gap: 20px;
  }

  .ct-left {
    height: 100%;
    width: 60%;
    display: flex;
    align-items: flex-end;
  }
  .ct-left img {
    width: 100%;
  }
  .ct-right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
  }

  .ct-right > input {
    display: flex;
    width: 80%;
    height: 80px;
    padding: 18px;
    align-items: center;
    flex-shrink: 0;

    border-radius: 10px;
    border: 1.5px solid rgba(255, 255, 255, 0.6);
    background-color: transparent;

    color: rgba(255, 255, 255, 0.6);
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    box-sizing: border-box;
  }

  .ct-right > input::placeholder {
    color: rgba(255, 255, 255, 0.6);
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ct-cta {
    border-radius: 10px;
    background: #ff7c00;
    display: flex;
    width: 80%;
    padding: 24px 0px 23px 0px;
    justify-content: center;
    align-items: center;

    color: #fff;
    font-family: Noto Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 15px;
  }
}

@media screen and (max-width: 600px) {
  .contact-cont {
    width: 95%;
    display: flex;
    flex-direction: column;

    border-radius: 15px;
    background: rgba(255, 255, 255, 0.05);
    margin: 50px 0;
    box-sizing: border-box;
    padding: 60px 0;
    gap: 20px;
  }

  .ct-inner-cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }

  .ct-left {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
  .ct-left img {
    width: 100%;
  }
  .ct-right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
  }

  .ct-right > input {
    display: flex;
    width: 80%;
    height: 80px;
    padding: 18px;
    align-items: center;
    flex-shrink: 0;

    border-radius: 10px;
    border: 1.5px solid rgba(255, 255, 255, 0.6);
    background-color: transparent;

    color: rgba(255, 255, 255, 0.6);
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    box-sizing: border-box;
  }

  .ct-right > input::placeholder {
    color: rgba(255, 255, 255, 0.6);
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ct-cta {
    border-radius: 10px;
    background: #ff7c00;
    display: flex;
    width: 80%;
    padding: 24px 0px 23px 0px;
    justify-content: center;
    align-items: center;

    color: #fff;
    font-family: Noto Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 15px;
  }
}
