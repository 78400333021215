.rev-cont {
  width: 88%;
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  justify-content: center;
  align-items: center;
  gap: 60px;
  position: relative;
}
.rev-head {
  width: fit-content;
}
.rev-cards-cont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 95px 0;
  position: relative;
  transition: all 1s ease-in-out;
}
.rev-card {
  /* min-height: 500px; */
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(100px);
  display: inline-flex;
  padding: 30px 44px 40px 44px;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
  width: 100%;
  box-sizing: border-box;
  z-index: 11;
  transition: all 1s ease-in-out;
}
.rev-card-btm {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.rev-card-btm img {
  width: 100px;
  height: 70px;
}
.revc-btm-in {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}
.revc-btm-head {
  color: rgba(255, 255, 255, 0.75);
  font-family: Noto Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.revc-btm-text {
  color: rgba(255, 255, 255, 0.6);
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.rev-c-main {
  color: rgba(255, 255, 255, 0.75);
  font-family: Noto Sans;
  font-size: clamp(18px, 2vw, 24px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.quote {
  color: rgba(255, 255, 255, 0.25);
  font-family: Noto Sans;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.rev-card-top-abs {
  position: absolute;
  transform: scale(0.4);
  top: -123px;
  left: -131px;
  z-index: 1;
  /* animation: topdown 1s ease-in-out; */
}
.rev-card-btm-abs {
  position: absolute;
  transform: scale(0.4);
  bottom: -123px;
  right: -131px;
  z-index: 1;
  /* animation: btmup 1s ease-in-out; */
}

.ecl4 {
  width: 610.831px;
  height: 610.831px;
  transform: rotate(1.421deg);
  flex-shrink: 0;
  border-radius: 610.831px;
  background: linear-gradient(
    270deg,
    rgba(9, 102, 142, 0.2) 0.03%,
    rgba(101, 188, 143, 0.2) 46.85%,
    rgba(255, 124, 0, 0.2) 99.95%
  );
  filter: blur(233.4979705810547px);

  position: absolute;
  right: -5%;
  top: -2%;
}

@media screen and (max-width: 1000px) {
  .rev-cont {
    width: 90%;
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    justify-content: center;
    align-items: center;
    gap: 60px;
    position: relative;
  }
  .rev-head {
    width: fit-content;
  }
  .rev-cards-cont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 95px 0;
    position: relative;
  }
  .rev-card {
    min-height: 0;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(100px);
    display: inline-flex;
    padding: 20px 34px 30px 34px;
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
    width: 100%;
    box-sizing: border-box;
    z-index: 11;
  }
  .rev-card-btm {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  .revc-btm-in {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  .revc-btm-head {
    color: rgba(255, 255, 255, 0.75);
    font-family: Noto Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .revc-btm-text {
    color: rgba(255, 255, 255, 0.6);
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .rev-c-main {
    color: rgba(255, 255, 255, 0.75);
    font-family: Noto Sans;
    font-size: clamp(14px, 2vw, 18px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .quote {
    color: rgba(255, 255, 255, 0.25);
    font-family: Noto Sans;
    font-size: 44px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .rev-card-top-abs {
    position: absolute;
    transform: scale(0.4);
    top: -123px;
    left: -131px;
    z-index: 1;
  }
  .rev-card-btm-abs {
    position: absolute;
    transform: scale(0.4);
    bottom: -123px;
    right: -131px;
    z-index: 1;
  }

  .ecl4 {
    width: 610.831px;
    height: 610.831px;
    transform: rotate(1.421deg);
    flex-shrink: 0;
    border-radius: 610.831px;
    background: linear-gradient(
      270deg,
      rgba(9, 102, 142, 0.2) 0.03%,
      rgba(101, 188, 143, 0.2) 46.85%,
      rgba(255, 124, 0, 0.2) 99.95%
    );
    filter: blur(233.4979705810547px);

    position: absolute;
    right: -5%;
    top: -2%;
  }
}

@media screen and (max-width: 600px) {
  .rev-cont {
    width: 95%;
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: relative;
  }
  .rev-head {
    width: 100%;
  }
  .rev-card-btm > img {
    width: 100px;
  }
  .rev-cards-cont {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 95px 0;
    position: relative;
  }
  .rev-card {
    min-width: 0;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(100px);
    display: inline-flex;
    padding: 20px 34px 30px 34px;
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
    width: 100%;
    box-sizing: border-box;
    z-index: 11;
  }
  .rev-card-btm {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  .revc-btm-in {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  .revc-btm-head {
    color: rgba(255, 255, 255, 0.75);
    font-family: Noto Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .revc-btm-text {
    color: rgba(255, 255, 255, 0.6);
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .rev-c-main {
    color: rgba(255, 255, 255, 0.75);
    font-family: Noto Sans;
    font-size: clamp(14px, 2vw, 18px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .quote {
    color: rgba(255, 255, 255, 0.25);
    font-family: Noto Sans;
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .rev-card-top-abs {
    position: absolute;
    transform: scale(0.4);
    top: -123px;
    left: -131px;
    z-index: 1;
  }
  .rev-card-btm-abs {
    position: absolute;
    transform: scale(0.4);
    bottom: -123px;
    right: -131px;
    z-index: 1;
  }

  .ecl4 {
    width: 610.831px;
    height: 610.831px;
    transform: rotate(1.421deg);
    flex-shrink: 0;
    border-radius: 610.831px;
    background: linear-gradient(
      270deg,
      rgba(9, 102, 142, 0.2) 0.03%,
      rgba(101, 188, 143, 0.2) 46.85%,
      rgba(255, 124, 0, 0.2) 99.95%
    );
    filter: blur(233.4979705810547px);

    position: absolute;
    right: -5%;
    top: -2%;
  }
}
