.wu-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 88%;
  padding: 50px 0;
  gap: 20px;
}

.wu-head-cont {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  color: rgba(255, 255, 255, 0.75);
  font-family: Noto Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 120px;
  overflow: hidden;
  position: relative;

  border-radius: 10px;
  background: #141416;
  backdrop-filter: blur(118.4000015258789px);
}

.wu-head-cont > svg {
  width: 72px;
  z-index: 1;
  height: 72px;
}

.wu-abs {
  position: absolute;
  left: 20%;
  height: 98%;
}
.wu-abs > svg {
  object-fit: cover;
}

.wu-card-cont {
  width: 100%;
  gap: 20px;
  display: flex;
  align-items: self-start;
  justify-content: center;
}

.wu-card {
  display: flex;
  width: calc(33.33% - 13px);
  min-height: 380px;
  box-sizing: border-box;

  padding: 25px 20px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #151517;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.25);
}
.wu-card img {
  height: 179.5px;
  width: 100%;
  max-width: 328px;
}
.wu-card-head {
  color: rgba(255, 255, 255, 0.75);
  font-family: Noto Sans;
  font-size: clamp(12px 3vw, 18px);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.wu-card-det {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-family: Noto Sans;
  font-size: clamp(10px, 1vw, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 90%;
}

.wu-c-m {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 179.5px;
  justify-content: center;
  gap: 10px;
}
.wum {
  border-radius: 30px;
  border: 1.5px solid var(--Brand-Gradient-2, #09668e);
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0 10px;

  color: #fff;
  text-align: center;
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 1000px) {
  .wu-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    padding: 50px 0;
    gap: 20px;
  }

  .wu-head-cont {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    color: rgba(255, 255, 255, 0.75);
    font-family: Noto Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    height: 120px;
    overflow: hidden;
    position: relative;

    border-radius: 10px;
    background: #141416;
    backdrop-filter: blur(118.4000015258789px);
  }

  .wu-head-cont > svg {
    width: 72px;
    z-index: 1;
    height: 72px;
  }

  .wu-abs {
    position: absolute;
    left: 20%;
    height: 98%;
  }
  .wu-abs > svg {
    object-fit: cover;
  }

  .wu-card-cont {
    width: 100%;
    gap: 20px;
    display: flex;
    align-items: self-start;
    justify-content: flex-start;
    overflow: scroll;
  }

  .wu-card {
    display: flex;
    width: fit-content;
    min-height: 380px;
    box-sizing: border-box;
    max-width: 380px;
    padding: 25px 20px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #151517;
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.25);
    flex-shrink: 0;
  }
  .wu-card img {
    height: 179.5px;
    width: 100%;
    max-width: 328px;
  }
  .wu-card-head {
    color: rgba(255, 255, 255, 0.75);
    font-family: Noto Sans;
    font-size: clamp(12px 3vw, 18px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }

  .wu-card-det {
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    font-family: Noto Sans;
    font-size: clamp(10px, 1vw, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 90%;
  }

  .wu-c-m {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 179.5px;
    justify-content: center;
    gap: 10px;
  }
  .wum {
    border-radius: 30px;
    border: 1.5px solid var(--Brand-Gradient-2, #09668e);
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0 10px;

    color: #fff;
    text-align: center;
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
@media screen and (max-width: 600px) {
  .wu-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding: 50px 0;
    gap: 20px;
  }

  .wu-head-cont {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    color: rgba(255, 255, 255, 0.75);
    font-family: Noto Sans;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    height: 120px;
    overflow: hidden;
    position: relative;

    border-radius: 10px;
    background: #141416;
    backdrop-filter: blur(118.4000015258789px);
  }

  .wu-head-cont > svg {
    width: 52px;
    z-index: 1;
    height: 52px;
  }

  .wu-abs {
    position: absolute;
    left: 0%;
    height: 98%;
    width: 100%;
  }
  .wu-abs > svg {
    object-fit: cover;
  }

  .wu-card-cont {
    width: 100%;
    gap: 20px;
    display: flex;
    align-items: self-start;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .wu-card {
    display: flex;
    width: 100%;
    min-height: 380px;
    box-sizing: border-box;

    padding: 25px 20px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #151517;
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.25);
  }
  .wu-card img {
    height: 179.5px;
    width: 100%;
    max-width: 328px;
  }
  .wu-card-head {
    color: rgba(255, 255, 255, 0.75);
    font-family: Noto Sans;
    font-size: clamp(12px 3vw, 18px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }

  .wu-card-det {
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    font-family: Noto Sans;
    font-size: clamp(10px, 1vw, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 90%;
  }

  .wu-c-m {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 179.5px;
    justify-content: center;
    gap: 10px;
  }
  .wum {
    border-radius: 30px;
    border: 1.5px solid var(--Brand-Gradient-2, #09668e);
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0 10px;

    color: #fff;
    text-align: center;
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
