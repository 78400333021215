@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gilroy:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Black+Han+Sans:wght@400&display=swap");

@font-face {
  font-family: "Gilroy";
  src: url("/src/Assests/fonts/Gilroy-Regular.ttf") format("truetype");

}
body{
  scroll-behavior: smooth;
}
.app {
  background: #050505;
}

*::-webkit-scrollbar {
  display: none;
}


