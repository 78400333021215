.www-cont {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  gap: 40px;
}
.www-head {
  color: rgba(255, 255, 255, 0.75);
  font-family: Noto Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.www-moving-cont {
  display: flex;
  width: 100%;
  height: 200px;
  padding: 3 0px 59px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
}
.greyImg {
  filter: grayscale(1);
}

.www-img-cont {
  width: fit-content;
  max-width: 200px;
  padding: 0 30px;
  height: fit-content;
  display: flex;
  max-height: 60px;
  justify-content: center;
}
.www-img-cont > img {
  width: 100%;
}
@media screen and (max-width: 600px) {
  .www-cont {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    gap: 20px;
  }
  .www-head {
    color: rgba(255, 255, 255, 0.75);
    font-family: Noto Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .www-moving-cont {
    display: flex;
    width: 100%;
    height: 200px;
    padding: 38px 0px 39px 0px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    box-sizing: border-box;
  }
  .greyImg {
    filter: grayscale(1);
  }
}
