.home-cont {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.hero-si-float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 111;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-si-float > svg {
  width: 50px;
  height: 50px;
}
